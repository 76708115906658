footer {
    a {
        text-decoration: none;
        color: #f8f9fa;
    }
    .socials {
        padding: 1rem 0;
        a {
            margin: 0 0.5rem;
        }
    }

    .site-navigation {
        a {
            margin: 0 0.5rem;
        }
    }
    .bottom {
        font-size: 0.8rem;
        padding: 0.5rem 0;
    }
}