header {
	position: fixed;
	background: inherit;
	width: 100vw;
	z-index: 999;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	overflow: hidden;
	backdrop-filter: blur(10px);

	&:before {
		content: “ ”;
		background: inherit;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.3);
		filter: blur(10px);
	}
}

.logo {
	display: flex;
	align-items: center;
	padding-right: 1rem;
	margin-right: 1rem;

	.brand {
		display: flex;
		white-space: nowrap;
		font-size: 1.375rem;
		font-weight: 600;
		align-items: center;

	}

	.zodiac {
		margin-right: .5rem;
	}

	svg {
		height: auto;
		width: 3rem;
	}
}

.menu {
	display: flex;
	align-items: center;

	.menu-item {
		margin-left: 2rem;
		list-style-type: none;

		a {
			position: relative;
			display: inline-flex;
			align-items: center;
			overflow: hidden;
			padding: 4px 0;

			>svg {
				margin-right: 2px;
			}

			.hover-effect {
				position: absolute;
				width: 100%;
				max-width: 0%;
				height: 2px;
				bottom: 0;
				transition: all 0.3s;
			}

			&:hover {
				.hover-effect {
					max-width: 100%;
				}
			}
		}
	}
}