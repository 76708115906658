.page-blog {
    .menu-opener {
        position: fixed;
        top: 5rem;
        left: 20px;

        .menu-icon {
            font-size: 0.6rem;
            background-color: #f7f7f7;
            background-image: linear-gradient(#f7f7f7, #e7e7e7);
            border-radius: 50%;
            box-shadow: 0 3px 8px #aaa, inset 0 2px 3px #fff;
            color: #878787;
            display: block;
            font-family: 'Poiret One', cursive;
            text-decoration: none;
            position: relative;
            width: 50px;
            height: 50px;
            line-height: 50px;

            &:before {
                background: #fff;
                border-top: 2px solid #ddd;
                border-radius: 50%;
                box-shadow: inset 0 8px 48px #ddd;
                content: '';
                display: block;
                position: absolute;
                left: -9px;
                right: -9px;
                top: -9px;
                bottom: -9px;
                z-index: -1;
            }

            &:hover {
                color: hsl(30, 70%, 50%);
            }
        }
    }
}