@mixin absolute-center($top: 50%) {
	position: absolute;
	top: $top;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.banner {
	display: flex;
	height: 100vh;
	background-color: #121519;
	background-image: url(./img/pexels.jpg);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;

	.banner-content {
		position: relative;
		height: 440px;
		color: rgba(255, 255, 255, .94);
		box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .2) inset, 0 1px 1px rgba(0, 0, 0, .3);
		text-shadow: 0 1px 1px rgba(25, 25, 25, .5);
		background: rgba(255, 255, 255, .06);
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			margin: -20px;
			filter: blur(6px);
			transition:
				all .2s ease-in-out,
				filter .8s .22s ease-in-out;
		}

		&>h1 {
			@include absolute-center();
			font-size: 5rem;
			margin-top: 0;
			transition: all .2s ease-in-out;
		}
	}
}